import Obfuscate from "react-obfuscate";
// import classNames from "classnames/bind";
import { ReactComponent as LogoHorizontal } from "../assets/images/brand/stasio-works-logo-full-horizontal.svg";
import OnLoadAnimator from "./OnLoadAnimator";

export default function Header() {
	return (
		<header className="primary">
			<OnLoadAnimator
				order={1}
				content={
					<a href="/">
						<LogoHorizontal />
					</a>
				}
			/>

			<nav>
				<OnLoadAnimator
					order={2}
					content={
						<a href="https://www.dropbox.com/scl/fi/nmezstxu3d8xu4ragj9dv/joe-stasio-resume.pdf?rlkey=ejpvc64q8pje67cgdtwrq7na3&dl=1">
							<button className="button-style">Resumé + Portfolio</button>
						</a>
					}
				/>
				<OnLoadAnimator
					order={3}
					content={
						<Obfuscate
							email="joe@stasio.works"
							aria-label="Email Me"
							className="button-style"
							headers={{
								subject: "I'd like to work with you, Joe!",
							}}
						>
							Email Me
						</Obfuscate>
					}
				/>
			</nav>
		</header>
	);
}
